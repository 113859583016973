import { Buffer } from "buffer";

import { useCallback, useEffect, useMemo, useState } from "react";

import { JsonRpcProvider } from "@ethersproject/providers";
import { BondWidget } from "@gravityfinance/widgets";
import { useWeb3React } from "@web3-react/core";
import { initializeConnector, Web3ReactHooks } from "@web3-react/core";
import { Connector } from "@web3-react/types";
import { Layout, ConfigProvider, theme } from "antd";

// import DisplayPane from "components/displayPane/DisplayPane";
import "styles/App.css";
import { coinbaseWallet } from "connectors/coinbaseWallet";
import { getName } from "connectors/getConnectorName";
import { metaMask } from "connectors/metaMask";
import { walletConnect } from "connectors/walletConnect";
import { CustomHeader, MainContent } from "layout";
import JsonRpcConnector from "utils/JsonRpcConnector";

// import { useSignerOrProvider } from "hooks";
type Web3ReactConnector<T extends Connector = Connector> = [T, Web3ReactHooks];

const styles = {
  layout: {
    width: "100%",
    height: "100vh",
    overflow: "auto",
    fontFamily: "Sora, sans-serif"
  }
} as const;

function App() {
  const { defaultAlgorithm, darkAlgorithm } = theme;
  const [isDarkMode, setIsDarkMode] = useState(true);
  if (!window.Buffer) window.Buffer = Buffer;
  const { isActive, provider, account  } = useWeb3React();

  // const { signer } = useSignerOrProvider();

  const activateConnector = useCallback(async (label: string) => {
    try {
      switch (label) {
        case "MetaMask":
          console.log("sdfsdf click metamask");
          await metaMask.activate();
          window.localStorage.setItem("connectorId", getName(metaMask));
          break;

        case "WalletConnect":
          await walletConnect.activate();
          window.localStorage.setItem("connectorId", getName(walletConnect));
          break;

        case "Coinbase Wallet":
          await coinbaseWallet.activate();
          window.localStorage.setItem("connectorId", getName(coinbaseWallet));
          break;

        default:
          break;
      }
    } catch (error) {
      // messageApi.error("Failed to connect wallet. Please try again.");
    }
  }, []);
  function initializeWeb3ReactConnector<T extends Connector, P extends object>(
    Constructor: { new (options: P): T },
    options: Omit<P, "actions">
  ): Web3ReactConnector<T> {
    const [connector, hooks] = initializeConnector((actions) => new Constructor({ actions, ...options } as P));
    if (options && "provider" in options) {
      // Short-circuit provider selection to improve performance and testability.
      // Without doing so, provider will be unavailable for a frame.
      hooks.useProvider = (() => (options as Record<"provider", unknown>).provider) as typeof hooks.useProvider;
    }
    return [connector, hooks];
  }

  const user = useMemo(() => {
    if (!provider) return;
    if (provider && provider._isProvider) {
      return initializeWeb3ReactConnector(JsonRpcConnector, { provider, onError: console.error });
    } else if (JsonRpcProvider.isProvider((provider as any).provider)) {
      throw new Error("Eip1193Bridge is experimental: pass your ethers Provider directly");
    }
    return null;
  }, [provider]);

  const shouldEagerlyConnect = provider === undefined; // !== null

  useEffect(() => {
    if (user && user[0]) {
      user[0].activate();
      return;
    } else if (shouldEagerlyConnect) {
      const eagerConnectors = [metaMask, walletConnect];
      eagerConnectors.forEach((connector) => connector.connectEagerly().catch(() => undefined));
    }
  }, []);


  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm
      }}
    >
      <Layout style={styles.layout}>
        <CustomHeader isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
        <MainContent>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "10px",
              maxWidth: "1200px",
              height: "100%"
            }}
          >
            
              <BondWidget
                defaultChainId={137}
                hideConnectionUI={true}
                onConnectWalletClick={() => {
                  // console.log("sdfsdf onConnectWalletClick");
                  activateConnector("MetaMask");
                }}
                ids={[]}
                provider={provider}
                strictMode={true}
                parentIsActive={isActive}
                account={account}
                showSaleVault={true}
              />
            
          </div>
        </MainContent>
        {/* <CustomFooter /> */}
      </Layout>
    </ConfigProvider>
  );
}

export default App;
