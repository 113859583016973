import { FC } from "react";

import {  Layout } from "antd";

// import dark_mode from "assets/images/dark_mode.png";
import easydefi_logo_dark from "assets/images/EasyDefiLogo_FA.png";
// import light_mode from "assets/images/light_mode.png";
// import web3Boilerplate_logo from "assets/images/web3Boilerplate_logo.png";
// import web3Boilerplate_logo_dark from "assets/images/web3Boilerplate_logo_dark.png";
import ConnectAccount from "components/Account/ConnectAccount";
import ChainSelector from "components/ChainSelector";
import { useWindowSize } from "hooks";

import headerstyles from "./Header.module.css";

const { Header } = Layout;

const styles = {
  header: {
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    // backgroundColor: "transparent",
    paddingTop: "15px",
    zIndex: 5,
    height: "fit-content"
  },
  headerRight: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    paddingRight: "10px",
    fontSize: "15px",
    fontWeight: "600"
  }
} as const;

type CustomHeaderProps = {
  isDarkMode: boolean;
  setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
};

const CustomHeader: FC<CustomHeaderProps> = ({ isDarkMode }) => {
  const { isTablet } = useWindowSize();

  // const toggleColorMode = () => {
  //   // setIsDarkMode((previousValue) => !previousValue);
  // };

  return (
    <Header
      className={headerstyles.header}
      style={{ ...styles.header, padding: isTablet ? "20px 5px 0 10px" : "30px 20px 20px 35px" }}
    >
      <Logo isDarkMode={isDarkMode} />
      <div style={styles.headerRight}>
        <ChainSelector />
        <ConnectAccount />
        {/* <Button
          shape="round"
          ghost
          onClick={toggleColorMode}
          style={{ height: "42px", padding: "5px 7px 0 10px", border: "none" }}
        >
          <img src={isDarkMode ? light_mode : dark_mode} alt="color mode" width="25px" />
        </Button> */}
      </div>
    </Header>
  );
};

export default CustomHeader;

type LogoProps = {
  isDarkMode: boolean;
};

export const Logo: FC<LogoProps> = ({ isDarkMode }) => {
  const { isTablet } = useWindowSize();

  return (
    <img
      src={isDarkMode ? easydefi_logo_dark : easydefi_logo_dark}
      alt="web3Boilerplate_logo"
      width={isTablet ? "180px" : "360px"}
    />
  );
};
