import { useQuery } from "@tanstack/react-query";
import { GraphQLClient } from "graphql-request";

import { ParsedUserNFTData, fetchUsersNFTData } from "./data";

const QUERY_SETTINGS_IMMUTABLE = {
  retry: 3,
  retryDelay: 3000,
  keepPreviousData: false,
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  refetchIntervalInBackground:false,

};

export const useUserMiningNFTData = (): {
  data: ParsedUserNFTData[] | undefined;
  isFetched: boolean;
  isFetching: boolean;
  isLoading: boolean;
} => {
  // const loyaltyGraph = new GraphQLClient("https://api.thegraph.com/subgraphs/name/agelesszeal/nft-minter");

  const loyaltyGraph = new GraphQLClient("https://gateway-arbitrum.network.thegraph.com/api/185a48a20ab1fb58bfca48f743bdbfba/subgraphs/id/CEJqUdzUEUSnGgSqmVWofnGnFXJe3eNm2uYTdG6h9PCZ");

  // const currentTime = +new Date();

  // const refresh = currentTime / 1000;

  const { data, isFetched, isFetching, isLoading } = useQuery({
    queryKey: [`nft-minter`],
    queryFn: () => fetchUsersNFTData(loyaltyGraph),

    enabled: true,
    ...QUERY_SETTINGS_IMMUTABLE,
    refetchInterval: 600000
  });

  

  return { data, isFetched, isFetching, isLoading };
};
