import { Route, Routes } from "react-router-dom";

import Minter from "./Minter";
import Vault from "./Vault";


export function App() {
  return (
    <Routes>
      <Route path="/" element={<Vault />} />
      <Route path="/btc-mining-admin" element={<Minter />} />
    </Routes>
  );
}
export default App;