import { gql, GraphQLClient } from "graphql-request";

export type Token = {
  id: string;
  tokenId: string;
  uri: string;
};

export type ExtendToken = {
  id: string;
  tokenId: number;
  uri: string;
  description?: string;
  image: string;
  name?: string;
  attributes?: { trait_type: string; value: string }[];
};

export type UserNFTEntry = {
  id: string;
  balance: string;
};

export type ParsedUserNFTData = {
  id: string;
  balance: number;
};

interface UserResults {
  owners: UserNFTEntry[];
}

export async function fetchUsersNFTData(client: GraphQLClient): Promise<ParsedUserNFTData[] | undefined> {
  try {
    const USERS_NFTS = gql`
      query owners {
        owners(first: 600) {
          balance
          id
        }
      }
    `;

    const data = await client.request<UserResults>(USERS_NFTS);
    // console.log("Sdfsdf data:", data);

    if (!data) {
      return undefined;
    }
    return data.owners
      .filter((item) => item.balance !== "0" && item.id !== "0x0000000000000000000000000000000000000000")
      .map((item) => {
        return {
          id: item.id,
          balance: parseInt(item.balance)
        };
      });
  } catch (err) {
    console.error("subgraph error:", err);
    return undefined;
  }
}
