import React from "react";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Web3ReactProvider } from "@web3-react/core";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";


import App from "./App";
import connectors from "./connectors";

const client = new QueryClient();
const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Web3ReactProvider connectors={connectors}>
      <QueryClientProvider client={client}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </QueryClientProvider>
    </Web3ReactProvider>
  </React.StrictMode>
);
